<template>
  <!-- v-if 频道切换后，轮播重新渲染，保证自动轮播 -->
  <div
    v-if="show"
    id="j-floating__ctn"
    ref="floatingIconBox"
    class="c-floating__ctn mshe-z-returnTop"
    :class="{ 'float-icon__hide': scrollHide }"
    :style="{'top': `${iconTop}rem` }"
  >
    <div
      class="c-floating__main j-floating__main handle"
      :style="{
        transform: `translateY(${iconPosition.move}px)`,
      }"
      tabindex="0"
      role="button"
      @touchstart="handleTouchStart"
      @touchmove.prevent.stop="handleTouchMove"
      @touchend="handleTouchEnd"
    >
      <div class="c-floating__main__inner">
        <swiper-container
          ref="floatingSwiper"
          class="c-floating__main__inner__container"
          init="false"
          destroy-on-disconnected="false"
        >
          <swiper-slide
            v-for="(item, index) in items"
            :key="index"
          >
            <BaseImg
              v-expose="getAnalysisData('2-22-1', {item, index, useBffApi})"
              v-tap="getAnalysisData('2-22-2', {item, index, useBffApi})"
              class="base-img c-floating__main__inner__container__slide__img"
              :placeholder="{
                width: item.image.width,
                height: item.image.height,
              }"
              :ratio="item.image.ratio"
              :img-src="item?.image?.src"
              :first-screen="false"
              :imgDataExp="{
                useWebp: item.useWebp,
              }"
              fit="contain"
              :special-radius="GB_cssRight ? '0 4% 4% 0' : '4% 0 0 4%'"
            />
          </swiper-slide>
        </swiper-container>
        <div
          class="c-floating__main__inner__close-icon"
          :style="{'visibility': show ? '' : 'hidden' }"
          @click="close"
        >
          <div class="c-floating__main__inner__close-icon__relative">
            <div class="icon-background"></div>
            <Icon
              name="sui_icon_close_10px"
              color="#fff"
              size="11"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* globals gbFixedContainerStyle */
import common from './mixins/common'
import mixins from 'public/src/pages/components/ccc/components/mixins/mixin.js'
import schttp from 'public/src/services/schttp'
import { transformImg, debounce, getQueryString, isNullOrUnDef } from '@shein/common-function'
import { isSwitchBffApiVersion } from 'public/src/pages/common/bff-new/index.js'
import Monitor from 'public/src/pages/common/home_common/monitor.js'

// components
import { Icon } from '@shein-aidc/icon-vue2'
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg'
import { register } from 'swiper/element'
import { Autoplay } from 'swiper/modules'
typeof window !== 'undefined' && register()
const { hasPolicyBanner = false, hasTopBanner = false, SiteUID, GB_cssRight = false } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
let timer = null
export default {
  name: 'FloatingIcon',
  components: {
    Icon, 
    BaseImg
  },
  mixins: [common, mixins],
  props: {
    // 二级首页频道id
    secondaryHomeChannelId: {
      type: [Number, String],
      default: ''
    },
    channelName: {
      type: String,
      default: ''
    },
    // 悬浮组件abt
    floatingIconAbt: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      pageType: 'floatingIcon',
      show: false,
      gbFixedContainerStyle,
      hasPolicyBanner,
      hasTopBanner,
      iconTop: 0,
      scrollHide: true,
      propData: {},
      cateLinks: {},
      cateInfo: {},
      iconCccPublic: {},
      iconPosition: {
        start: 0, // 鼠标按下时候的位置
        moveStartY: 0, // 上一次icon偏移的距离
        move: 0, // icon 需要偏移多少
        maxMove: 0 // 最大位移高度
      },
      abtInfo: {},
      floatingSwiper: null,
      items: [],
      previewChannelId: getQueryString({ key: 'contentId' }) || false, // ccc-admin预览
      debugger: getQueryString({ key: 'debugger' }) || false,
      scrollHideTime: 0, // 显示后再隐藏的间隔时间
      GB_cssRight,
    }
  },
  computed: {
    // 当前频道，首页、二级首页
    curChannelId() {
      return this.previewChannelId || this.secondaryHomeChannelId || this.channelId
    },
    // 页面跳转参数
    sceneData() {
      const { id = '' } = this.propData || {}
      return {
        pageFrom: this.pageType,
        pageType: this.pageType,
        sceneName: this.channelName,
        tabName: this.channelName,
        id,
      }
    },
    useBffApi() {
      return isSwitchBffApiVersion(['home_v1'])?.['home_v1']
    },
  },
  watch: {
    scrollHide(newVal) {
      // 悬浮球侧边隐藏时，停止轮播
      newVal ? this.pauseSwiperAutoplay() : this.playSwiperAutoplay()
    },
    async curChannelId() {
      // 切换频道后再获取数据
      this.scrollHide = true
      await this.init()
    }
  },
  async mounted() {
    this.curChannelId && await this.init()
  },
  activated() {
    if (window.sessionStorage?.getItem(SiteUID + '-floating-icon-close')) return
    // 进入页面后返回，组件激活即开始监听和轮播
    this.initScroll()
    this.initSwiper()
  },
  deactivated() {
    if (requestIdleCallback) {
      requestIdleCallback(() => {
        this.handleDeactivated()
      })
    } else {
      setTimeout(() => {
        this.handleDeactivated()
      }, 0)
    }
  },
  methods: {
    handleDeactivated() {
      // 离开组件销毁监听，降低性能消耗
      // this.dragdealer = null
      $(window).unbind('scroll.floatingIcon')
      // 离开组件，重置显示，保证返回页面后正常轮播
      this.scrollHide = true
      this.show = false
    },
    transformImg,
    // 处理swiper为loop时 事件绑定的问题
    initSwiper() {
      this.$nextTick(() => {
        if (typeof window === 'undefined') return
        const swiperEl = this.$refs.floatingSwiper
        if (!swiperEl) return
        const swiperParams = {
          modules: [Autoplay],
          direction: 'vertical',
          loop: true,
          spaceBetween: 0,
          speed: 1000,
          slidesPerView: 1,
          slidesPerGroup: 1,
          allowTouchMove: false,
          autoplay: {
            delay: 1000,
            disableOnInteraction: false,
          },
        }
        Object.assign(swiperEl, swiperParams)
        swiperEl.initialize()
      })
    },
    // 处理swiper为loop时 事件绑定的问题
    handleSlideClick() {
      //悬浮icon右侧收起后，点击不触发
      if (this.scrollHide) return
      let realIndex = this.$refs.floatingSwiper?.swiper?.realIndex || 0
      let item = this.items[realIndex]
      let url = this.cccLink.getFullLink({
        item: item,
        cateLinks: this.cateLinks,
        compIndex: 0,
        index: (realIndex + 1)
      })
      this.jumpLink({ url, hrefType: item.hrefType })
    },
    playSwiperAutoplay() {
      this.$refs.floatingSwiper?.swiper?.autoplay.start()
    },
    pauseSwiperAutoplay() {
      this.$refs.floatingSwiper?.swiper?.autoplay.stop()
    },
    getTouches(e) {
      return (
        (e.targetTouches && (e.targetTouches[0] || e.changedTouches[0])) || {}
      )
    },
    handleTouchStart(e) {
      const { pageY } = this.getTouches(e)
      this.iconPosition.start = pageY
      this.iconPosition.moveStartY = this.iconPosition.move
      // 防止点击跟拖拽冲突
      timer = setTimeout(() => {
        this.handleSlideClick()
      }, 200)
    },
    handleTouchMove(e) {
      // 拖拽场景不触发点击
      clearTimeout(timer)
      timer = null
      const { pageY } = this.getTouches(e)
      // 偏移量
      const maxMove = this.iconPosition.maxMove
      const distance = this.iconPosition.moveStartY + pageY - this.iconPosition.start
      const move = distance < 0 ? 0 : distance > maxMove ? maxMove : distance
      this.iconPosition.move = Number(move) 
      this.pauseSwiperAutoplay()
    },
    handleTouchEnd() {
      // 记录上一次上下滑动位置
      window.sessionStorage?.setItem(SiteUID + '-floating-icon-pos', this.iconPosition.move)
      this.playSwiperAutoplay()
    },
    async init() {
      if (window.sessionStorage?.getItem(SiteUID + '-floating-icon-close')) return
      this.calcIconTop()
      await this.fetchData()
      this.initScroll()
      this.initSwiper()
      const initMoveHeight = this.calcInitMoveHeight()
      this.iconPosition.maxMove = initMoveHeight
      this.iconPosition.move = Number(window.sessionStorage?.getItem(SiteUID + '-floating-icon-pos') || initMoveHeight || 1)
      this.handleSlslog()
    },
    // 计算初始的上距离
    calcIconTop() {
      let top = this.hasPolicyBanner ? `${+(gbFixedContainerStyle.top.replace('rem', '')) - +(gbFixedContainerStyle.policybanner.replace('rem', '')) + +(gbFixedContainerStyle.branch.replace('rem', '')) + 1.1733}` : `${+(gbFixedContainerStyle.top.replace('rem', '')) + +(gbFixedContainerStyle.branch.replace('rem', '')) + 1.1733}`
      if (!this.hasTopBanner) {
        const hasTopBannerHeight = Math.ceil($('.j-config-top-banner').height() || 0)
        hasTopBannerHeight !== 0 && (top = top + ((hasTopBannerHeight * 2) / 75).toFixed(2))
      }
      if (gbFixedContainerStyle.branch === '0rem') {
        top = parseFloat(top) + 2.02
      }
      this.iconTop = top
    },
    // 计算滑动距离
    calcInitMoveHeight() {
      const htmlFontSize = parseFloat(getComputedStyle(document.documentElement)?.fontSize) ?? 0
      // 页面高度 - 顶部距离(banner+header+tablist) - bottom固定的4.9rem
      const top = this.iconTop
      const topHeight = parseFloat(top)
      const footerHeight = 6.4 // 4.9rem bottom
      const remainingHeight = document.documentElement.clientHeight - topHeight * htmlFontSize - footerHeight * htmlFontSize
      return remainingHeight.toFixed(0)
    },
    // 页面滚动监听
    initScroll() {
      if (!this.items.length) {
        this.show = false
        return
      }
      this.show = true
      Monitor.metricFloatComp({
        tags: {
          track_type: '3',
          layer_type: 'FloatingIcon',
        },
      })
      setTimeout(() => {
        this.scrollHide = false
      }, 500)
      $(window).bind('scroll.floatingIcon', () => {
        const gapTime = new Date().getTime() - this.scrollHideTime
        // 计算显示后再隐藏时间，续大于动画完成时间，防止显示后马上再滚动马上隐藏造成的抖动
        if (!this.scrollHide && (!this.scrollHideTime || gapTime > 500)) {
          this.scrollHide = true
        }
      })
      // 滚动后显示icon的防抖
      $(window).bind('scroll.floatingIcon', debounce({ func: this.onScrollFun, wait: 500 }))
    },
    onScrollFun() {
      this.scrollHide = false
      this.scrollHideTime = new Date().getTime()
    },
    async bffIconRequest() {
      const params = {
        cccPageType: 'floatingIcon',
        channelId: this.curChannelId,
      }
      try {
        const res = await schttp({
          url: '/ccc/resource_place',
          method: 'GET',
          useBffApi: true,
          params
        })
        return {
          content: res?.info || {},
          code: res?.code
        }
      } catch (error) {
        console.error('Error in bffIconRequest:', error)
        return {
          content: {},
          code: -1
        }
      }
    },
    async fetchData() {
      const { isSupportWeb = '' } = this.context?.RESOURCE_SDK || {}
      if (window.sessionStorage?.getItem(SiteUID + '-floating-icon-close')) return
      if (!this.curChannelId) return
      const res = await this.bffIconRequest()
      const { code, content } = res
      if (code == 0) {
        this.propData = content?.content?.[0] || {}
        this.cateLinks = content?.cateLinks || {}
        let items = []
        if (content?.content?.[0]?.props?.items.length) {
          // 逐帧显示 abt 控制
          content?.content?.[0]?.props?.items.forEach((element) => {
            let showFrame = true
            if (!isNullOrUnDef(element.frame) && element.frame === 1 && this.floatingIconAbt.IfShowFrame1 === 'false') {
              showFrame = false
            }
            if (!isNullOrUnDef(element.frame) && element.frame === 2 && this.floatingIconAbt.IfShowFrame2 === 'false') {
              showFrame = false
            }
            if (!isNullOrUnDef(element.frame) && element.frame === 3 && this.floatingIconAbt.IfShowFrame3 === 'false') {
              showFrame = false
            }
            if (showFrame && element.image?.src) {
              let transformUrl = this.transformImg({ img: element.image?.src })
              element.useWebp = 0
              if (/\.(jpg|jpeg|png)$/.test(transformUrl)) {
                if (isSupportWeb === 1) {
                  transformUrl =  transformUrl.replace(/\b.(jpg|jpeg|png)\b/, '.webp')
                  element.useWebp = 1
                }
              }
              element.image.src = transformUrl
              items.push(element)
            }
          })
        }
        this.items = items
      }
    },
    close() {
      clearTimeout(timer)
      timer = null
      this.show = false
      window.sessionStorage?.setItem(SiteUID + '-floating-icon-close', 1)
      Monitor.metricFloatComp({
        tags: {
          track_type: '4',
          layer_type: 'FloatingIcon',
        },
      })
    },
    handleSlslog() {
      this.items.map(el => {
        if(!el?.image?.src) {
          this.hideCompSls({
            logLevel: 3,
            tag: 'web_client_home_error_total',
            message: 'HomeOtherDataError',
            reason: 'imageError',
          })
        }
      })
    },
  }
}
</script>
<style lang="less">
@vw: 375/100vw;
.c-floating {
  &__ctn {
    position: fixed;
    right: 0;
    bottom: 4.9rem;// 72+47+65
    top: 2rem;
    width: 52 / @vw;
    pointer-events: none;
    transition: transform .5s, opacity .5s;
    &.float-icon__hide {
      transform: translateX(30 / @vw);
      opacity: 0.5;
    }
  }
  &__main {
    position: absolute;
    right: 0;
    top: 0;
    width: 1.39rem;
    height: 1.73rem;
    text-align: center;
    pointer-events: all;
    &__inner { /* stylelint-disable-line */
      position: relative;
      width: 100%;
      height: 100%;
      &__container { /* stylelint-disable-line */
        width: 4.17rem;
        height: 5.19rem;
        transform: translate(-33.33%, -33.33%) scale(0.3333); // 最后再缩小1/3，容器固定32*38自适应大小
        overflow: hidden;
        &__slide__img { /* stylelint-disable-line */
          width: 100%;
          height: 100%;
        }
      }
      &__close-icon { /* stylelint-disable-line */
        width: 0.5333rem;
        height: 0.32rem;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(0, -100%);
        pointer-events: all;
        overflow: hidden; // hidden 保证点击热区为矩形而非icon组件的正方形
        will-change: transform; // 部分安卓手机渲染丢帧
        &__relative { /* stylelint-disable-line */
          position: relative;
          width: 100%;
          height: 100%;
          .icon-background {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 100%;
            background-image: url('//img.ltwebstatic.com/images3_ccc/2024/08/19/1a/1724068000332b363f18576ea6f51e751a83d63b18.png');
            background-size: contain;
            background-repeat: no-repeat;
          }
          .sui-icon-common__wrap {
            position: absolute;
            bottom: 0;
            line-height: 10px !important; /* stylelint-disable-line declaration-no-important */
            right: 3px /* rtl: 2px */;
          }
        }
      }
    }
  }
}
// 中东镜像
[mir=rtl] {
  .c-floating__main__inner__close-icon { /* stylelint-disable-line */
    transform: translate(0, -100%) scale(-1,1);
  }
} 
</style>

